// import { INIT } from '../actions';
// import initApp from './init';
/**
 * Redux
 */

const initialState = {
	authenticated: false
};

function reducer(state = initialState, action) {
	// switch (action.type) {
	//     case INIT:
	//         return initApp(state, initialState);
	//         break;
	//     default:
	//         return state;
	//         break;
	// }
}

export default reducer;
